/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./chartist.css");
@import url("./leaflet.css");

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"), url("./fonts/proxima-nova-black/font.woff") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Black.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Italic.ttf");
  font-style: italic;
}

.headline {
  text-shadow: 2px 2px #000;
}

#map {
  position: sticky !important;
}

.ql-editor {
  min-height: 200px;
}

table.angebot td:not(:first-child) {
  width: 100px;
}

.chart-table table th:first-child,
.chart-table table td:first-child {
  display: none;
}

/** Tooltips ********/

[data-tooltip]:before {
  margin: 18px 0 0 32px;
  font-weight: 600;
  border-radius: 5px;
  background: #585858;
  color: #fff;
  content: attr(data-tooltip);
  font-size: 12px;
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  padding: 4px 7px;
  position: absolute;
  transform: scale(0);
  white-space: nowrap;
  transform-origin: top right;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

[data-tooltip]:hover:before {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transform-origin: right center 0;
}

[data-tooltip-placement]:hover:before {
  margin: 0 0 0 -160px;
}

/** CHARTS ********/

.ct-series-a .ct-bar {
  /* Colour of your bars */
  stroke: #84bd00 !important;
  /* The width of your bars */
  stroke-width: 28px;
}

.ct-series-b .ct-bar {
  /* Colour of your bars */
  stroke: #608a00 !important;
  /* The width of your bars */
  stroke-width: 28px;
}

.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
}

.ct-legend li {
  position: relative;
  padding-left: 28px;
  margin-bottom: 3px;
}

.ct-legend li:before {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-radius: 2px;
}

.ct-legend li.inactive:before {
  background: transparent;
}

.ct-legend .ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}

.ct-legend .ct-series-0:before {
  background: #84bd00;
}

.ct-legend .ct-series-1:before {
  background: #608a00;
}
